import React from 'react';
import Navbar from './components/Navbar';
import SpotlightPreview from "./components/SpotlightPreview";
import ContactForm from "./components/ContactForm";
import { BackgroundGradientAnimation } from "./components/ui/BackgroundGradientAnimation";
import { BentoGrid, BentoGridItem } from "./components/ui/BentoGrid";
import { LampDemo } from './components/ui/Lamps';
import BlogSection from './components/BlogSection';
import {BiggerAnimatedGradientBackground} from "./components/ui/BiggerAnimatedGradientBackground";
import AnimatedGradientBackgroundMobile from "./components/ui/AnimatedBackgroundForSmallerScreens";
import {useMediaQuery} from "react-responsive";
import './App.css';

function App() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    const BackgroundComponent = isTabletOrMobile ? AnimatedGradientBackgroundMobile : BiggerAnimatedGradientBackground;

    const projects = [
        {
            title: "Linked Recommendation",
            description: "A soft gradient background with carousel Testimonials",
            projectDetails: {
                image: "/path-to-image.jpg",
                description: "Linked Recommendation is a dynamic web application that showcases user testimonials in an elegant carousel format, set against a soothing gradient background.",
                features: [
                    "Responsive carousel for testimonials",
                    "Customizable gradient background",
                    "Smooth animations for enhanced user experience",
                    "Integration with various data sources for testimonials"
                ]
            },
            projectLink: "https://linked-recommendation.example.com"
        },
        {
            title: "React Portfolio",
            description: "An older version of this portfolio",
            projectDetails: {
                image: "/path-to-image.jpg",
                description: "This React Portfolio was an earlier iteration of my personal website, showcasing my projects and skills in a clean, modern design.",
                features: [
                    "Responsive layout for all devices",
                    "Interactive project gallery",
                    "Smooth scrolling and animations",
                    "Contact form with validation"
                ]
            },
            projectLink: "https://old-portfolio.example.com"
        },
        {
            title: "RainDrop Explorer",
            description: "Explore thousands of my bookmarks in a visual way - built with Raindrop API",
            projectDetails: {
                image: "/path-to-image.jpg",
                description: "RainDrop Explorer is a unique tool that allows users to visually navigate through a vast collection of bookmarks, leveraging the power of the Raindrop API.",
                features: [
                    "Visual bookmark exploration interface",
                    "Integration with Raindrop API",
                    "Advanced filtering and search capabilities",
                    "Customizable bookmark organization"
                ]
            },
            projectLink: "https://raindrop-explorer.example.com"
        },
        {
            title: "Barber Shop Booking System",
            description: "A Complex Ecommerce System to create a shop, book appointments, and manage inventory",
            projectDetails: {
                image: "/path-to-image.jpg",
                description: "The Barber Shop Booking System is a comprehensive e-commerce solution designed specifically for barber shops, offering appointment booking, inventory management, and shop creation capabilities.",
                features: [
                    "User-friendly appointment booking interface",
                    "Real-time inventory tracking",
                    "Custom shop creation tools",
                    "Integration with payment gateways",
                    "Analytics dashboard for business insights"
                ]
            },
            projectLink: "https://barber-booking.example.com"
        },
        {
            title: "Tellmelive",
            description: "A Social Media Platform for sharing live events and Connecting with others",
            projectDetails: {
                image: "/path-to-image.jpg",
                description: "The Barber Shop Booking System is a comprehensive e-commerce solution designed specifically for barber shops, offering appointment booking, inventory management, and shop creation capabilities.",
                features: [
                    "User-friendly appointment booking interface",
                    "Real-time inventory tracking",
                    "Custom shop creation tools",
                    "Integration with payment gateways",
                    "Analytics dashboard for business insights"
                ]
            },
            projectLink: "https://barber-booking.example.com"
        },
    ];

    return (
        <div className="App bg-black from-gray-900 to-black-100 min-h-screen">
            <Navbar/>
            <section id="home" className="min-h-screen flex items-center justify-center">
                <SpotlightPreview/>
            </section>
            <BackgroundGradientAnimation>
                <section id="projects" className="min-h-screen relative">
                    <div className="relative z-10 min-h-screen flex items-center justify-center">
                        <div className="w-full max-w-7xl mx-auto px-4 py-20">
                            <h2 className="text-4xl font-bold text-center mb-10 text-white">Most Recent Projects</h2>
                            <BentoGrid className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                                {projects.map((project, i) => (
                                    <BentoGridItem
                                        key={i}
                                        title={project.title}
                                        description={project.description}
                                        className={`${i === 4 || i === 5 ? "md:col-span-2" : ""} mb-4`}
                                        header={
                                            <div className="bg-gray-100 dark:bg-gray-800 aspect-video w-full rounded-lg"></div>
                                        }
                                        projectDetails={project.projectDetails}
                                        projectLink={project.projectLink}
                                    />
                                ))}
                            </BentoGrid>
                        </div>
                    </div>
                </section>
            </BackgroundGradientAnimation>
            <BackgroundComponent>
                <section id="about" className="min-h-screen relative overflow-hidden">
                    <div className="absolute inset-0 w-full h-full laptopAndLargeScreenOnly">
                        <LampDemo className="w-full h-full" />
                    </div>
                    <div className="relative z-10 flex items-center justify-center min-h-screen tabletsandPhonescreenonly">
                        <div className="bg-black bg-opacity-70 p-6 rounded-lg">
                            <LampDemo className="w-full h-full" />
                        </div>
                    </div>
                </section>
                <section id="blog" className="w-full relative z-10">
                    <BlogSection />
                </section>
                <div className="divider text-white">Let's Talk</div>
                <section id="contact" className="min-h-screen relative z-10">
                    <div className="w-full h-full flex items-center justify-center">
                        <ContactForm />
                    </div>
                </section>
            </BackgroundComponent>
            <footer className="footer footer-center bg-black text-base-content p-4 relative z-10">
                <aside>
                    <p className="text-white text-center">Copyright © {new Date().getFullYear()} - All right reserved by Othman Adi</p>
                </aside>
            </footer>
        </div>
    );
}

export default App;