import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Input } from './ui/input';
import { Label } from './ui/label';
import {IconBrandGithub, IconBrandGmail, IconBrandGoogle, IconBrandOffice, IconSend} from '@tabler/icons-react';
import { Button } from "./ui/moving-border";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const ContactForm = () => {
    const [formState, setFormState] = useState({
        from_name: '',
        from_email: '',
        message: ''
    });

    // Hardcoded EmailJS User ID
    const EMAILJS_USER_ID = 'X71BMdQ2haeclC36D';

    useEffect(() => {
        emailjs.init(EMAILJS_USER_ID);
        console.log('EmailJS initialized with User ID:', EMAILJS_USER_ID);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log('Sending email with form state:', formState);

        emailjs.send(
            "service_le9w57l",
            "template_taxmtvj",
            formState,
            EMAILJS_USER_ID
        )
            .then((result) => {
                console.log('Success:', result.text);
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully'
                });
                setFormState({ from_name: '', from_email: '', message: '' });
            }, (error) => {
                console.log('Error:', error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, something went wrong',
                    text: error.text,
                });
            });
    };

    return (
        <div className="w-full max-w-md mx-auto p-6 bg-white dark:bg-zinc-800 rounded-xl shadow-lg mt-20 md:max-w-lg lg:max-w-xl xl:max-w-2xl">
            <h2 className="text-2xl font-bold text-center mb-6 text-gray-800 dark:text-white"></h2>
            <p className="text-center mb-6 text-gray-600 dark:text-gray-300 lg:text-lg">
                I'm always excited to connect with fellow developers and potential collaborators.
                Don't hesitate to reach out!
            </p>
            <form onSubmit={handleSubmit} className="space-y-4 lg:space-y-6">
                <div>
                    <Label htmlFor="from_name" className="lg:text-lg">Name</Label>
                    <Input
                        id="from_name"
                        name="from_name"
                        value={formState.from_name}
                        onChange={handleInputChange}
                        placeholder="Your Name Please"
                        required
                        className="lg:text-lg lg:p-3"
                    />
                </div>
                <div>
                    <Label htmlFor="from_email" className="lg:text-lg">Email</Label>
                    <Input
                        id="from_email"
                        name="from_email"
                        type="email"
                        value={formState.from_email}
                        onChange={handleInputChange}
                        placeholder="You@Domain.Something"
                        required
                        className="lg:text-lg lg:p-3"
                    />
                </div>
                <div>
                    <Label htmlFor="message" className="lg:text-lg">Message</Label>
                    <textarea
                        id="message"
                        name="message"
                        value={formState.message}
                        onChange={handleInputChange}
                        placeholder="Please be as detailed as you want! :)"
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 dark:bg-zinc-700 dark:text-white dark:border-gray-600 lg:text-lg lg:p-3"
                        rows="4"
                        required
                    ></textarea>
                </div>
                <div className="mt-8 flex justify-center space-x-4">
                    <Button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full px-4 py-2 text-black border-none bg-transparent rounded-lg hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 lg:text-lg lg:py-3"
                        type="submit"
                    >
                        Get in touch
                        <IconSend className="inline-block mr-2 ml-2" size={20} />
                    </Button>
                </div>
            </form>
            <div className="mt-6 flex justify-center space-x-4">
                <motion.a
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white transition-colors duration-300"
                    href="https://github.com/othmanadi"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IconBrandGithub size={35} className="lg:w-10 lg:h-10" />
                </motion.a>
                <motion.a
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="text-gray-600 hover:text-red-500 dark:text-gray-300 dark:hover:text-white transition-colors duration-300"
                    href="mailto:github_me@outlook.de"
                >
                    <IconBrandGmail size={35} className="lg:w-10 lg:h-10" />
                </motion.a>
            </div>
        </div>
    );
};

export default ContactForm;