import React, { useState, useEffect, useRef } from "react";
import { Spotlight } from "./ui/Spotlight";
import { TypewriterEffectSmooth } from "./ui/typewriter-effect";
import { Button } from "./ui/moving-border";
import { ButtonContact } from "./ui/moving-border-green";

const SpotlightPreview = () => {
    const [inSpotlight, setInSpotlight] = useState(false);
    const [key, setKey] = useState(0);
    const spotlightRef = useRef(null);

    const quote = [
        { text: "When" },
        { text: "Someone" },
        { text: "Tells" },
        { text: "you" },
        { text: "something" },
        { text: "is" },
        { text: "impossible," },
        { text: "do" },
        { text: "it" },
        { text: "twice" },
        { text: "and" },
        { text: "take" },
        { text: "photos" },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInSpotlight(true);
                    setKey(prevKey => prevKey + 1);
                } else {
                    setInSpotlight(false);
                }
            },
            { threshold: 0.5 }
        );

        if (spotlightRef.current) {
            observer.observe(spotlightRef.current);
        }

        return () => {
            if (spotlightRef.current) {
                observer.unobserve(spotlightRef.current);
            }
        };
    }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div ref={spotlightRef} className="min-h-screen w-full rounded-md flex flex-col items-center justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
            <Spotlight
                className="-top-40 left-0 md:left-60 md:-top-20"
                fill="white"
            />
            <div className="p-4 max-w-7xl mx-auto relative z-10 w-full text-center">
                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50">
                    Othman Adi
                </h1>
                <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-neutral-200 mt-4">
                    Software Developer & Educator
                </h2>
                <p className="mt-4 text-base sm:text-lg md:text-xl font-normal text-neutral-300 max-w-3xl mx-auto">
                    With over 12 years of experience in development, I've had the privilege of teaching more than 5,000
                    lectures on coding and programming to students of all ages. My passion lies in launching careers, having
                    helped numerous adults transition into full stack development, software engineering, and cybersecurity.
                </p>
                <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <Button
                        className="w-full sm:w-auto px-4 sm:px-6 py-2 text-white border-none bg-transparent hover:bg-gray-300 hover:text-black transition-colors text-sm sm:text-base"
                        onClick={() => scrollToSection('projects')}
                    >
                        View Projects
                    </Button>
                    <ButtonContact
                        className="w-full sm:w-auto px-4 sm:px-6 py-2 text-green-600 border border-green-600 hover:bg-green-400 hover:text-white transition-colors text-sm sm:text-base"
                        onClick={() => scrollToSection('contact')}
                    >
                        Contact Me
                    </ButtonContact>
                </div>
                <div className="mt-12 sm:mt-16 md:mt-20">
                    {inSpotlight && (
                        <TypewriterEffectSmooth
                            key={key}
                            words={quote}
                            startDelay={300}
                            textClassName="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-extrabold tracking-tight text-white leading-tight break-words"
                            cursorClassName="h-6 sm:h-8 md:h-10 lg:h-12 xl:h-14"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SpotlightPreview;