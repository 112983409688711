import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";

export const TypewriterEffectSmooth = ({
                                           words,
                                           className,
                                           cursorClassName,
                                           startDelay = 0,
                                           textClassName = ""
                                       }) => {
    const [isAnimated, setIsAnimated] = useState(false);
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
        setIsAnimated(true);
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    }, []);

    const wordsArray = words.map((word) => ({
        ...word,
        text: word.text.split(""),
    }));

    const renderWords = () => (
        <div style={{ width: containerWidth, overflow: 'hidden' }}>
            {wordsArray.map((word, idx) => (
                <span key={`word-${idx}`} className="inline-block">
          {word.text.map((char, index) => (
              <span
                  key={`char-${index}`}
                  className={cn(`dark:text-white text-neutral-200`, word.className)}
              >
              {char}
            </span>
          ))}
                    &nbsp;
        </span>
            ))}
        </div>
    );

    return (
        <div className={cn("flex items-center justify-center", className)} ref={containerRef}>
            <motion.div
                className="overflow-hidden"
                initial={{ width: "0%" }}
                animate={isAnimated ? { width: "100%" } : {}}
                transition={{ duration: 2, ease: "linear", delay: 1 + startDelay / 1000 }}
            >
                <div
                    className={cn(
                        "font-medium",
                        textClassName
                    )}
                    style={{ width: containerWidth, whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                    {renderWords()}
                </div>
            </motion.div>
            <motion.span
                initial={{ opacity: 0 }}
                animate={isAnimated ? { opacity: 1 } : {}}
                transition={{ duration: 0.8, repeat: Infinity, repeatType: "reverse", delay: startDelay / 1000 }}
                className={cn(
                    "block rounded-sm w-[2px] h-[1.2em] bg-blue-500",
                    cursorClassName
                )}
            ></motion.span>
        </div>
    );
};