import React, { useRef, useEffect, useState } from "react";
import { cn } from "../../utils/cn";
import { TypewriterEffectSmooth } from "./typewriter-effect";
import { motion } from "framer-motion";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalTrigger } from "./animated-modal";

// Import images
import aiPoolImage from "../../images/ai-pool-web-app-1024x768desktop-920193.png";
import codingWithAdiImage from "../../images/codingwithadi-b876e-web-app-1024x768desktop-75e483.png";
import adeptlyImage from "../../images/adeptly-961a7-web-app-1024x768desktop-8f0458.png";
import tellMeLiveImage from "../../images/apps-apple-com-1024x768desktop-3e13ae.png";
import barberBuddyImage from "../../images/barberbuddy-store-1024x768desktop-b3ddd6.png";
import migravenImage from "../../images/migraven-teams-explorer-oauth-web-app-1024x768desktop-bf1b75.png";
import raindropExplorerImage from "../../images/raindropexplorer-web-app-1024x768desktop-3a942f.png";
import linkedinBackgroundGradientImage from "../../images/LinkedInRecommendationsBackground.png"
export const BentoGrid = ({ className }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    const quote2 = [
        { text: "No" },
        { text: "one" },
        { text: "can" },
        { text: "stop" },
        { text: "an" },
        { text: "Entrepreneur" },
        { text: "who" },
        { text: "is" },
        { text: "having" },
        { text: "fun" },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div ref={ref}>
            <div
                className={cn(
                    "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 max-w-7xl mx-auto p-5",
                    className
                )}
            >
                <BentoGridItem
                    title="BarberBuddy"
                    description="Store manager for barbers"
                    projectDetails={{
                        image: barberBuddyImage,
                        description: "A store management solution for barbers, launched in under 12 hours.",
                        features: [
                            "Built with React",
                            "TinyCm text editor integration",
                            "Map view for finding barber addresses",
                            "Barber link generation"
                        ]
                    }}
                    projectLink="https://barberbuddy.store/"
                    className="col-span-1 row-span-2 h-auto sm:h-[300px] md:h-[400px] lg:h-auto"
                />
                <BentoGridItem
                    title="AI Pool"
                    description="AI tool listing for Arabic users"
                    projectDetails={{
                        image: aiPoolImage,
                        description: "A comprehensive tool listing AI tools written for Arabic readers and users.",
                        features: [
                            "Built with Flutter and Dart",
                            "Arabic-focused interface",
                            "Curated list of AI tools"
                        ]
                    }}
                    projectLink="https://ai-pool.web.app/#/"
                    className="col-span-1 sm:col-span-2 lg:col-span-2 row-span-2 h-auto sm:h-[300px] md:h-[400px] lg:h-auto"
                />
                <BentoGridItem
                    title="Coding with Adi"
                    description="Portfolio with Maximalism focus"
                    projectDetails={{
                        image: codingWithAdiImage,
                        description: "A portfolio website showcasing projects with a maximalist design approach.",
                        features: [
                            "Built with React and Firebase",
                            "Maximalist design philosophy",
                            "Interactive project showcase"
                        ]
                    }}
                    projectLink="https://codingwithadi-b876e.web.app/"
                    className="col-span-1 row-span-1 h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                />
                <BentoGridItem
                    title="Adeptly"
                    description="Agency website"
                    projectDetails={{
                        image: adeptlyImage,
                        description: "The official website for Adeptly agency, showcasing services and portfolio.",
                        features: [
                            "Built with Next.js",
                            "Responsive design",
                            "Service showcase and contact information"
                        ]
                    }}
                    projectLink="https://adeptly-961a7.web.app/"
                    className="col-span-1 row-span-1 h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                />
                <BentoGridItem
                    title="TellMeLive"
                    description="Social media app for iOS and Android"
                    projectDetails={{
                        image: tellMeLiveImage,
                        description: "A cross-platform social media app available on Apple App Store and Google Play Store.",
                        features: [
                            "Built with Flutter",
                            "Real-time social interactions",
                            "Cross-platform compatibility"
                        ]
                    }}
                    projectLink="https://apps.apple.com/pl/app/tellmelive/id1587145814"
                    className="col-span-1 sm:col-span-2 lg:col-span-2 row-span-1 h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                />

                <BentoGridItem
                    title="Migraven Teams Explorer"
                    description="Microsoft Graph API integration"
                    projectDetails={{
                        image: migravenImage,
                        description: "A web app that interacts with Microsoft Graph API to display organizational data.",
                        features: [
                            "Built with React",
                            "OAuth authentication",
                            "Data visualization of organizational structure"
                        ]
                    }}
                    projectLink="https://migraven-teams-explorer-oauth.web.app/"
                    className="col-span-1 sm:col-span-2 lg:col-span-2 row-span-1 h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                />
                <BentoGridItem
                    title="Raindrop Explorer"
                    description="Bookmark management and exploration"
                    projectDetails={{
                        image: raindropExplorerImage,
                        description: "A web app that pulls and displays over 4k bookmarks from Raindrop account using their API.",
                        features: [
                            "Built with React",
                            "Integration with Raindrop API",
                            "Efficient display of large number of bookmarks",
                            "Public sharing of curated bookmarks"
                        ]
                    }}
                    projectLink="https://raindropexplorer.web.app/"
                    className="col-span-1 row-span-1 h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                />
                <BentoGridItem
                    title="LinkedIn Recommendations"
                    description="A Landing-page that is focused on my linkedIn Recommendations"
                    projectDetails={{
                        image: linkedinBackgroundGradientImage,
                        description: "A Landing-page that showcase my linkedIn Recommendations with a cute Gradient Background",
                        features: [
                            "Built with React",
                            "Plain old Bootstrap",
                            "Complex Css'ing for the Background Gradient"
                        ]
                    }}
                    projectLink="https://linkedinrecommend.web.app/"
                    className="col-span-1 sm:col-span-2 lg:col-span-2 row-span-1 h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                />
                <BentoGridItem
                    title="Your Idea, Your Speed"
                    description="The next big thing could be yours"
                    className="col-span-1 row-span-1 cursor-default h-auto sm:h-[250px] md:h-[300px] lg:h-auto"
                    isPlaceholder={true}
                />
            </div>
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={isVisible ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="mt-8 sm:mt-12 md:mt-16 text-center px-4 sm:px-6 md:px-8"
            >
                {isVisible && (
                    <TypewriterEffectSmooth
                        words={quote2}
                        textClassName="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-extrabold tracking-tight text-neutral-800 dark:text-neutral-200 leading-tight break-words"
                        cursorClassName="h-6 sm:h-8 md:h-10 lg:h-12 xl:h-14 bg-neutral-800 dark:bg-neutral-200"
                    />
                )}
            </motion.div>
        </div>
    );
};

export const BentoGridItem = ({
                                  className,
                                  title,
                                  description,
                                  icon,
                                  projectDetails,
                                  projectLink,
                                  isPlaceholder = false
                              }) => {
    if (isPlaceholder) {
        return (
            <div className={cn(
                "rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white border border-transparent justify-between flex flex-col space-y-4",
                className
            )}>
                <div className="flex flex-col items-center justify-center h-full text-center">
                    {icon}
                    <h3 className="mt-4 font-bold text-neutral-600 dark:text-neutral-200">{title}</h3>
                    <p className="mt-2 text-sm text-neutral-500 dark:text-neutral-400">{description}</p>
                </div>
            </div>
        );
    }

    return (
        <Modal>
            <ModalTrigger className={cn(
                "rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white border border-transparent justify-between flex flex-col space-y-4",
                className
            )}>
                <div className="group-hover/bento:translate-x-2 transition duration-200 h-full flex flex-col">
                    <div className="aspect-video w-full relative rounded-lg overflow-hidden mb-4 flex-grow">
                        <img
                            src={projectDetails.image}
                            alt={title}
                            className="object-cover w-full h-full"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 group-hover/bento:opacity-100 transition-opacity duration-300">
                            <span className="text-white text-sm font-medium">View Details</span>
                        </div>
                    </div>
                    {icon}
                    <div className="font-sans text-left font-bold text-neutral-600 dark:text-neutral-200 mb-2 mt-2">
                        {title}
                    </div>
                    <div className="font-sans text-left font-normal text-neutral-600 text-xs dark:text-neutral-300">
                        {description}
                    </div>
                </div>
            </ModalTrigger>
            <ModalBody>
                <ModalContent>
                    <h2 className="text-xl sm:text-2xl text-left font-bold mb-4">{title}</h2>
                    <div className="mb-6">
                        <img src={projectDetails.image} alt={title} className="w-full h-36 sm:h-48 object-cover rounded-lg mb-4" />
                        <p className="text-sm sm:text-base text-neutral-600 dark:text-neutral-300">{projectDetails.description}</p>
                    </div>
                    <h3 className="text-lg sm:text-xl font-semibold mb-2">Key Features:</h3>
                    <ul className="list-disc list-inside mb-6 text-sm sm:text-base">
                        {projectDetails.features.map((feature, index) => (
                            <li key={index} className="text-neutral-600 dark:text-neutral-300">{feature}</li>
                        ))}
                    </ul>
                </ModalContent>
                <ModalFooter>
                    <a
                        href={projectLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 text-sm sm:text-base"
                    >
                        Visit Project
                    </a>
                </ModalFooter>
            </ModalBody>
        </Modal>
    );
};