import React, {useEffect, useRef, useState} from "react";
import {cn} from "../../utils/cn";
import {TypewriterEffectSmooth} from "./typewriter-effect";
import {motion} from "framer-motion";

export const BackgroundGradientAnimation = ({
                                                gradientBackgroundStart = "rgb(108, 0, 162)",
                                                gradientBackgroundEnd = "rgb(0, 17, 82)",
                                                firstColor = "18, 113, 255",
                                                secondColor = "221, 74, 255",
                                                thirdColor = "100, 220, 255",
                                                fourthColor = "200, 50, 50",
                                                fifthColor = "180, 180, 50",
                                                pointerColor = "140, 100, 255",
                                                size = "80%",
                                                blendingValue = "hard-light",
                                                children,
                                                className,
                                                interactive = true,
                                                containerClassName,
                                            }) => {
    const interactiveRef = useRef(null);
    const [isSafari, setIsSafari] = useState(false);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});

    const quote2 = [
        {text: "No"},
        {text: "one"},
        {text: "can"},
        {text: "stop"},
        {text: "an"},
        {text: "Entrepreneur"},
        {text: "who"},
        {text: "is"},
        {text: "having"},
        {text: "fun"},
    ];

    useEffect(() => {
        setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

        const styles = [
            {key: "--gradient-background-start", value: gradientBackgroundStart},
            {key: "--gradient-background-end", value: gradientBackgroundEnd},
            {key: "--first-color", value: firstColor},
            {key: "--second-color", value: secondColor},
            {key: "--third-color", value: thirdColor},
            {key: "--fourth-color", value: fourthColor},
            {key: "--fifth-color", value: fifthColor},
            {key: "--pointer-color", value: pointerColor},
            {key: "--size", value: size},
            {key: "--blending-value", value: blendingValue},
        ];

        styles.forEach(({key, value}) => {
            document.documentElement.style.setProperty(key, value);
        });
    }, [
        gradientBackgroundStart, gradientBackgroundEnd, firstColor, secondColor,
        thirdColor, fourthColor, fifthColor, pointerColor, size, blendingValue
    ]);

    const handleMouseMove = (event) => {
        if (interactiveRef.current) {
            const rect = interactiveRef.current.getBoundingClientRect();
            setMousePosition({
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
            });
        }
    };

    return (
        <div
            ref={interactiveRef}
            onMouseMove={handleMouseMove}
            className={cn(
                "relative w-full h-full overflow-hidden",
                "bg-[linear-gradient(40deg,var(--gradient-background-start),var(--gradient-background-end))]",
                containerClassName
            )}
        >
            <div className={cn("absolute inset-0", className)}>
                <div className={cn(
                    "absolute inset-0 w-full h-full",
                    isSafari ? "blur-2xl" : "[filter:url(#blurMe)_blur(40px)]"
                )}>
                    {/* Gradient divs */}
                    <motion.div
                        animate={{
                            x: mousePosition.x,
                            y: mousePosition.y,
                        }}
                        transition={{type: "spring", damping: 10, stiffness: 50}}
                        className="absolute [background:radial-gradient(circle_at_center,_var(--first-color)_0,_var(--first-color)_50%)_no-repeat] [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] animate-first opacity-100"
                    ></motion.div>
                    <motion.div
                        animate={{
                            x: mousePosition.x * 0.5,
                            y: mousePosition.y * 0.5,
                        }}
                        transition={{type: "spring", damping: 10, stiffness: 50}}
                        className="absolute [background:radial-gradient(circle_at_center,_rgba(var(--second-color),_0.8)_0,_rgba(var(--second-color),_0)_50%)_no-repeat] [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] animate-second opacity-100"
                    ></motion.div>
                    <motion.div
                        animate={{
                            x: mousePosition.x * 0.25,
                            y: mousePosition.y * 0.25,
                        }}
                        transition={{type: "spring", damping: 10, stiffness: 50}}
                        className="absolute [background:radial-gradient(circle_at_center,_rgba(var(--third-color),_0.8)_0,_rgba(var(--third-color),_0)_50%)_no-repeat] [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] animate-third opacity-100"
                    ></motion.div>
                    <motion.div
                        animate={{
                            x: mousePosition.x * 0.75,
                            y: mousePosition.y * 0.75,
                        }}
                        transition={{type: "spring", damping: 10, stiffness: 50}}
                        className="absolute [background:radial-gradient(circle_at_center,_rgba(var(--fourth-color),_0.8)_0,_rgba(var(--fourth-color),_0)_50%)_no-repeat] [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] animate-fourth opacity-70"
                    ></motion.div>
                    <motion.div
                        animate={{
                            x: mousePosition.x * 0.35,
                            y: mousePosition.y * 0.35,
                        }}
                        transition={{type: "spring", damping: 10, stiffness: 50}}
                        className="absolute [background:radial-gradient(circle_at_center,_rgba(var(--fifth-color),_0.8)_0,_rgba(var(--fifth-color),_0)_50%)_no-repeat] [mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)] animate-fifth opacity-100"
                    ></motion.div>
                </div>
            </div>
            <div className="relative z-10 text-white p-4 sm:p-6 md:p-8">
                {children}
            </div>
        </div>
    );
};