import React, { useEffect, useState, useRef } from "react";
import { cn } from "../../utils/cn";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";

export const InfiniteMovingCards = ({
                                        items,
                                        direction = "right",
                                        speed = "slow",
                                        pauseOnHover = true,
                                        className,
                                    }) => {
    const containerRef = useRef(null);
    const scrollerRef = useRef(null);
    const [start, setStart] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0);
    const [scrollerWidth, setScrollerWidth] = useState(0);

    const [{ x }, api] = useSpring(() => ({ x: 0 }));

    useEffect(() => {
        addAnimation();
        if (containerRef.current && scrollerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
            setScrollerWidth(scrollerRef.current.scrollWidth);
        }
    }, []);

    function addAnimation() {
        if (containerRef.current && scrollerRef.current) {
            const scrollerContent = Array.from(scrollerRef.current.children);

            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                scrollerRef.current.appendChild(duplicatedItem);
            });

            setAnimationProperties();
            setStart(true);
        }
    }

    const setAnimationProperties = () => {
        const animationDirection = direction === "left" ? "reverse" : "forwards";
        const animationDuration = speed === "fast" ? "80s" : speed === "normal" ? "120s" : "180s";

        if (containerRef.current) {
            containerRef.current.style.setProperty("--animation-direction", animationDirection);
            containerRef.current.style.setProperty("--animation-duration", animationDuration);
        }
    };

    const bind = useDrag(
        ({ active, movement: [mx], direction: [xDir], velocity, cancel }) => {
            if (active && Math.abs(mx) > scrollerWidth / 2) {
                cancel();
            }
            api.start({
                x: active ? mx : 0,
                immediate: active,
                config: { velocity: xDir * velocity },
            });
        },
        { axis: "x", bounds: { left: -scrollerWidth + containerWidth, right: 0 } }
    );

    return (
        <div
            ref={containerRef}
            className={cn(
                "scroller relative z-20 max-w-7xl overflow-hidden",
                className
            )}
            style={{
                maskImage: "linear-gradient(to right, transparent, white 20%, white 80%, transparent)",
            }}
        >
            <animated.ul
                {...bind()}
                ref={scrollerRef}
                style={{ x }}
                className={cn(
                    "flex min-w-full shrink-0 gap-4 py-4 w-max flex-nowrap",
                    start ? "animate-scroll" : "",
                    pauseOnHover ? "hover:[animation-play-state:paused]" : ""
                )}
            >
                {items.map((item, idx) => (
                    <li
                        className="w-[280px] sm:w-[350px] max-w-full relative rounded-2xl border border-slate-700 px-4 sm:px-8 py-4 sm:py-6 md:w-[450px] cursor-grab active:cursor-grabbing"
                        style={{
                            background: "linear-gradient(180deg, var(--slate-800), var(--slate-900))",
                        }}
                        key={idx}
                    >
                        <div className="absolute top-2 left-2 bg-cyan-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs font-bold">
                            {idx + 1}
                        </div>
                        <blockquote>
                            <span className="text-sm sm:text-base md:text-lg leading-[1.6] text-gray-100">
                                {item.quote}
                            </span>
                            <div className="mt-4 sm:mt-6">
                                <span className="text-xs sm:text-sm md:text-base text-gray-400">
                                    {item.name} - <span className="text-gray-500">{item.title}</span>
                                </span>
                            </div>
                        </blockquote>
                    </li>
                ))}
            </animated.ul>
        </div>
    );
};