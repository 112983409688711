import React, { useEffect } from 'react';
import { cn } from "../../utils/cn";

export const AnimatedGradientBackgroundMobile = ({
                                                     gradientBackgroundStart = "rgb(108, 0, 162)",
                                                     gradientBackgroundEnd = "rgb(0, 17, 82)",
                                                     firstColor = "18, 113, 255",
                                                     secondColor = "221, 74, 255",
                                                     thirdColor = "100, 220, 255",
                                                     fourthColor = "200, 50, 50",
                                                     fifthColor = "180, 180, 50",
                                                     size = "80%",
                                                     blendingValue = "hard-light",
                                                     children,
                                                     className,
                                                     containerClassName,
                                                 }) => {
    useEffect(() => {
        document.body.style.setProperty(
            "--gradient-background-start",
            gradientBackgroundStart
        );
        document.body.style.setProperty(
            "--gradient-background-end",
            gradientBackgroundEnd
        );
        document.body.style.setProperty("--first-color", firstColor);
        document.body.style.setProperty("--second-color", secondColor);
        document.body.style.setProperty("--third-color", thirdColor);
        document.body.style.setProperty("--fourth-color", fourthColor);
        document.body.style.setProperty("--fifth-color", fifthColor);
        document.body.style.setProperty("--size", size);
        document.body.style.setProperty("--blending-value", blendingValue);
    }, []);

    return (
        <div className={cn("relative", containerClassName)}>
            <div className={cn("relative z-10", className)}>{children}</div>
            <div className="fixed inset-0 w-full h-full pointer-events-none">
                <div className="absolute inset-0 w-full h-full bg-[linear-gradient(40deg,var(--gradient-background-start),var(--gradient-background-end))]"></div>
                <div className="absolute inset-0 w-full h-full overflow-hidden">
                    <div className="absolute inset-0 w-full h-full blur-lg">
                        <div
                            className={cn(
                                `absolute [background:radial-gradient(circle_at_center,_var(--first-color)_0,_var(--first-color)_50%)_no-repeat]`,
                                `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
                                `[transform-origin:center_center]`,
                                `animate-first`,
                                `opacity-100`
                            )}
                        ></div>
                        <div
                            className={cn(
                                `absolute [background:radial-gradient(circle_at_center,_rgba(var(--second-color),_0.8)_0,_rgba(var(--second-color),_0)_50%)_no-repeat]`,
                                `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
                                `[transform-origin:calc(50%-400px)]`,
                                `animate-second`,
                                `opacity-100`
                            )}
                        ></div>
                        <div
                            className={cn(
                                `absolute [background:radial-gradient(circle_at_center,_rgba(var(--third-color),_0.8)_0,_rgba(var(--third-color),_0)_50%)_no-repeat]`,
                                `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
                                `[transform-origin:calc(50%+400px)]`,
                                `animate-third`,
                                `opacity-100`
                            )}
                        ></div>
                        <div
                            className={cn(
                                `absolute [background:radial-gradient(circle_at_center,_rgba(var(--fourth-color),_0.8)_0,_rgba(var(--fourth-color),_0)_50%)_no-repeat]`,
                                `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
                                `[transform-origin:calc(50%-200px)]`,
                                `animate-fourth`,
                                `opacity-70`
                            )}
                        ></div>
                        <div
                            className={cn(
                                `absolute [background:radial-gradient(circle_at_center,_rgba(var(--fifth-color),_0.8)_0,_rgba(var(--fifth-color),_0)_50%)_no-repeat]`,
                                `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
                                `[transform-origin:calc(50%-800px)_calc(50%+800px)]`,
                                `animate-fifth`,
                                `opacity-100`
                            )}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnimatedGradientBackgroundMobile;