import React from "react";
import { CardBody, CardContainer, CardItem } from "./ui/3d-card";

import Image1 from "../images/DALL·E 2024-07-11 11.31.18 - A visually striking image depicting a modern, digital workspace with multiple monitors displaying code, symbolizing coding. The environment is filled .webp";
import Image2 from "../images/DALL·E 2024-07-11 11.31.19 - A conceptual image representing business innovation, featuring a sleek modern office with a large glass table surrounded by high-tech chairs. The room.webp";
import Image3 from "../images/DALL·E 2024-07-11 11.31.30 - An image of a cozy, inviting reading nook with a comfortable chair, a small side table, and a standing lamp. The area is surrounded by overflowing boo.webp";
import Image4 from "../images/DALL·E 2024-07-11 11.31.32 - A vibrant image of a modern classroom with diverse students engaged in learning. The room is filled with colorful educational posters, high-tech equip.webp";
import Image5 from "../images/DALL·E 2024-07-11 11.31.36 - A creative and modern depiction of a person brainstorming business ideas, set in a stylish urban office space. The individual is using a large whitebo.webp";
import Image6 from "../images/DALL·E 2024-07-11 11.31.33 - An artistic representation of someone deeply engaged in writing code. The focus is on a person's hands typing rapidly on a futuristic keyboard with gl.webp";


const BlogPost = ({ title, description, imageUrl, link }) => {

    return (
        <CardContainer className="inter-var">
            <CardBody className="bg-gray-50 relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-full h-full rounded-xl p-4 sm:p-6 border">
                <CardItem
                    translateZ="50"
                    className="text-lg sm:text-xl font-bold text-neutral-600 dark:text-white"
                >
                    {title}
                </CardItem>
                <CardItem
                    as="p"
                    translateZ="60"
                    className="text-neutral-500 text-xs sm:text-sm mt-2 dark:text-neutral-300"
                >
                    {description}
                </CardItem>
                <CardItem translateZ="100" className="w-full mt-4">
                    <img
                        src={imageUrl}
                        className="h-48 sm:h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl"
                        alt={title}
                    />
                </CardItem>
                <div className="flex justify-end items-center mt-6 sm:mt-8">
                    <CardItem
                        translateZ={20}
                        as="a"
                        href={link}
                        className="px-3 sm:px-4 py-1 sm:py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold"
                    >
                        Read More
                    </CardItem>
                </div>
            </CardBody>
        </CardContainer>
    );
};

const BlogSection = () => {
    const blogPosts = [
        {
            title: "Building a Web-Based Chat Assistant with OpenAI Integration",
            description: "Learn how to create a powerful chat assistant using OpenAI's API, enhancing your web applications with AI-driven conversations.",
            imageUrl: Image1,
            link: "https://devdojo.com/github-me/building-a-web-based-chat-assistant-with-openai-integration-part1"
        },
        {
            title: "Comprehensive Learning Path Dashboard with Next.js and Raindrop API",
            description: "Discover how to build an interactive dashboard for tracking your learning progress using Next.js and the Raindrop API.",
            imageUrl: Image2,
            link: "https://dev.to/othmanadi/building-a-comprehensive-learning-path-dashboard-with-nextjs-and-raindrop-api-dem"
        },
        {
            title: "Creating a Spotify Song Downloader with SpotDL, React, and Express",
            description: "Explore the process of building a Spotify song downloader application using SpotDL, React for the frontend, and Express for the backend.",
            imageUrl: Image3,
            link: "https://dev.to/othmanadi/creating-a-spotify-song-downloader-dancing-with-spotdl-react-and-express-27ea"
        },
        {
            title: "Displaying Database Data in AR with Swift and Firebase",
            description: "Learn how to create an augmented reality experience that showcases database information using Swift and Firebase integration.",
            imageUrl: Image4,
            link: "https://dev.to/othmanadi/show-database-data-in-ar-with-swift-and-firebase-59o4"
        },
        {
            title: "Centering UI Components: A Fun Dive into React's Flexibility",
            description: "Explore various techniques for centering UI components in React, showcasing the framework's flexibility and power.",
            imageUrl: Image5,
            link: "https://dev.to/othmanadi/-centering-ui-components-a-fun-dive-into-reacts-flexibility-59ib"
        },
        {
            title: "+160 Prompts for Artists and Content Creator\n",
            description: "Discover a comprehensive list of creative prompts designed to inspire artists and content creators in their work.",
            imageUrl: Image6,
            link: "+160 Prompts for Artists and Content Creator"
        }
    ];

    return (
        <div className="w-full py-12 sm:py-16 bg-transparent dark:bg-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-100 dark:text-white text-center mb-8 sm:mb-12">
                    The Knowledge I Share With The World
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                    {blogPosts.map((post, index) => (
                        <BlogPost key={index} {...post} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogSection;