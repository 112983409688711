import React, { useState, useEffect, useCallback } from 'react';
import { Menu, MenuItem, HoveredLink } from "./ui/navbar-menu";
import { cn } from "../utils/cn";
import useSmoothScroll from '../components/hooks/useSmoothScroll';

const Navbar = () => {
    const [active, setActive] = useState(null);
    const [visible, setVisible] = useState(true);
    const [atTop, setAtTop] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const scrollToSection = useSmoothScroll();

    const TOP_THRESHOLD = 50; // pixels from top to consider "at top"
    const SCROLL_THRESHOLD = 50; // minimum scroll distance to trigger hide/show

    const controlNavbar = useCallback(() => {
        if (typeof window !== 'undefined') {
            const currentScrollY = window.scrollY;

            if (currentScrollY <= TOP_THRESHOLD) {
                setAtTop(true);
                setVisible(true);
            } else {
                setAtTop(false);

                if (Math.abs(currentScrollY - lastScrollY) > SCROLL_THRESHOLD) {
                    setVisible(currentScrollY < lastScrollY);
                }
            }

            setLastScrollY(currentScrollY);
        }
    }, [lastScrollY]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [controlNavbar]);

    const handleClick = (e, sectionId) => {
        e.preventDefault();
        scrollToSection(sectionId);
    };

    return (
        <div
            className={cn(
                "fixed top-0 left-0 w-full bg-transparent z-50 pt-4 pb-4 transition-all duration-300",
                atTop ? "translate-y-0" : (visible ? "translate-y-0" : "-translate-y-full"),
                atTop ? "" : "bg-transparent"
            )}
        >
            <div className="relative w-full flex items-center justify-center">
                <Menu setActive={setActive}>
                    <MenuItem setActive={setActive} active={active} item="Home">
                        <HoveredLink href="#home" onClick={(e) => handleClick(e, 'home')}>Home</HoveredLink>
                    </MenuItem>
                    <MenuItem setActive={setActive} active={active} item="Projects">
                        <HoveredLink href="#projects" onClick={(e) => handleClick(e, 'projects')}>Projects</HoveredLink>
                    </MenuItem>
                    <MenuItem setActive={setActive} active={active} item="About">
                        <HoveredLink href="#about" onClick={(e) => handleClick(e, 'about')}>About Me</HoveredLink>
                    </MenuItem>
                    <MenuItem setActive={setActive} active={active} item="Blog">
                        <HoveredLink href="#blog" onClick={(e) => handleClick(e, 'blog')}>Blog</HoveredLink>
                    </MenuItem>
                    <MenuItem setActive={setActive} active={active} item="Contact">
                        <HoveredLink href="#contact" onClick={(e) => handleClick(e, 'contact')}>Contact</HoveredLink>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default Navbar;