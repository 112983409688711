import React from "react";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";
import { InfiniteMovingCards } from "./infinityMovingCards";
import {useInView} from "react-spring";

const testimonials = [
    {
        quote: "Bis jetzt einer der besten Coding Lehrer die ich je hatte. Man merkt direkt mit wie viel Herzblut er die anderen versucht, fürs coden zu begeistern. Jeder der bei ihm lernen kann lernt von einem der Besten.",
        name: "Romano Mantek",
        title: "Junior Full Stack Web Developer",
    },
    {
        quote: "Othman Adi is a great teacher: Patient, kind and very generous. I had a wonderful time at StartSteps in his courses about Web Development and Cyber security.",
        name: "Malina Vaagt",
        title: "Curating Assistant at Slug Space",
    },
    {
        quote: "Othman was a great teacher when it comes to the tech related topics. I had the pleasure to be on the Compass Course of StartSteps with him. His energy, enthusiasm and passion was contagious.",
        name: "Anh Nguyen",
        title: "Selbstständig",
    },
    {
        quote: "Ahmad is one of the most influential instructors, coaches, mentors I have met on my journey to break into tech. Adi's passion for teaching extended beyond the course schedule.",
        name: "Vernell Clark",
        title: "Full Stack Software Engineering Student",
    },
    {
        quote: "Highly professional, friendly, well-mannered, patient, intelligent, and funny - this is all about Adi. He was the first person who showed me the world of coding when I took the Compass Сourse from StartSteps.",
        name: "Liliia Shvorob",
        title: "Junior Full Stack Web Developer",
    },
    {
        quote: "I had the pleasure of being a student in Adi's web development class, and I can confidently say that it was one of the best learning experiences I have had.",
        name: "Ivan Curmi",
        title: "MBA, ACCA, and Data Science Student",
    },
    {
        quote: "I had the pleasure of completing the web development course with JavaScript and React under your guidance, and I can confidently say that it was one of the best learning experiences I've had.",
        name: "Motahhareh Samadi",
        title: "Electronic Engineer",
    },
    {
        quote: "Adi was a pleasure! Patient and with a wealth of knowledge, his generosity to our class was amazing. He set us up with resources and materials for our start into the Tech industry!",
        name: "Aidan Nee",
        title: "Web Developer",
    },
    {
        quote: "Hey beautiful people😊, I had a great opportunity to learn coding (html,css,JavaScript) through my amazing teacher Ahmad Othman Ammar Adi.",
        name: "Ismail Alkanj",
        title: "Junior Full-Stack Web & App Developer",
    },
    {
        quote: "Adi was one of the best teachers I have ever had. Very patient and kind. Always an open ear for questions. I learned a lot from him in a very short time.",
        name: "Beau S.",
        title: "UI/UX Design | Junior Web- & App Developer",
    },
    {
        quote: "Had the opportunity to be mentored by Adi and he is a great mentor and very knowledgeable in web development.",
        name: "Mile Ristov",
        title: "Front-End Developer",
    },
    {
        quote: "Sooo, I took an orientation course by the StartSteps Company, where I had the opportunity to learn about different Branches in IT. My teacher of Web development was Adi!",
        name: "Zoriana Atamanchuk",
        title: "HR-Intern",
    },
    {
        quote: "Adi wie wir Ihnen alle nennen dürfen, ist ein sehr guter Dozent bei StartStep und sehr hilfsbereit. Danke Adi mach weiter so.",
        name: "Daniel Biedenstein",
        title: "Orientierungsphase",
    },
    {
        quote: "At StartSteps, Adi taught me two subjects: Web Development and Cyber Security. Adi possesses full technical proficiency in the subjects he teaches.",
        name: "João Teixeira",
        title: "Software Engineer | Fullstack Developer",
    },
    {
        quote: "I enjoyed the Web Development classes with Adi and I really learned a lot from him. Adi has a very unique way of teaching, involving motivation and enthusiasm.",
        name: "Vullnet Veliaj",
        title: "Digital Marketing Freelancer",
    },
    {
        quote: "Adi is a great colleague and a highly supportive person at any team initiative! I've been always impressed with his willingness to help the team at any time with immense dedication!",
        name: "Lum Dalipi",
        title: "Product Management • EdTech • Software Engineering",
    },
    {
        quote: "Adi is a teacher who has the ability to make students become passionate about what they are learning, and be surprised by new topics and internalize what they have learned.",
        name: "Daniel Castro",
        title: "Industrial + Interior Designer",
    },
    {
        quote: "If someone is looking for an energetic, passionate, knowledgeable, and friendly person to teach them, well look no further than this guy right here - Adi!",
        name: "Nikolay Dimitrov",
        title: "Customer Support Specialist * UX/UI Designer",
    },
];

export const LampContainer = ({ children, className }) => {
    return (
        <div
            className={cn(
                "relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-slate-950 w-full rounded-md z-0",
                className
            )}
        >
            <div className="relative flex w-full flex-1 scale-y-125 items-center justify-center isolate z-0 ">
                <motion.div
                    initial={{ opacity: 0.5, width: "15rem" }}
                    whileInView={{ opacity: 1, width: "30rem" }}
                    transition={{
                        duration: 0.3,
                        ease: "easeInOut",
                    }}
                    style={{
                        backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
                    }}
                    className="absolute inset-auto right-1/2 h-56 overflow-visible w-[30rem] bg-gradient-conic from-cyan-500 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top] sm:w-[30rem] sm:h-56 max-sm:w-[20rem] max-sm:h-40"
                >
                    <div className="absolute  w-[100%] left-0 bg-slate-950 h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
                    <div className="absolute  w-40 h-[100%] left-0 bg-slate-950  bottom-0 z-20 [mask-image:linear-gradient(to_right,white,transparent)]" />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0.5, width: "15rem" }}
                    whileInView={{ opacity: 1, width: "30rem" }}
                    transition={{
                        duration: 0.3,
                        ease: "easeInOut",
                    }}
                    style={{
                        backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
                    }}
                    className="absolute inset-auto left-1/2 h-56 w-[30rem] bg-gradient-conic from-transparent via-transparent to-cyan-500 text-white [--conic-position:from_290deg_at_center_top] sm:w-[30rem] sm:h-56 max-sm:w-[20rem] max-sm:h-40"
                >
                    <div className="absolute  w-40 h-[100%] right-0 bg-slate-950  bottom-0 z-20 [mask-image:linear-gradient(to_left,white,transparent)]" />
                    <div className="absolute  w-[100%] right-0 bg-slate-950 h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
                </motion.div>
                <div className="absolute top-1/2 h-48 w-full translate-y-12 scale-x-150 bg-slate-950 blur-2xl"></div>
                <div className="absolute top-1/2 z-50 h-48 w-full bg-transparent opacity-10 backdrop-blur-md"></div>
                <div className="absolute inset-auto z-50 h-36 w-[28rem] -translate-y-1/2 rounded-full bg-cyan-500 opacity-50 blur-3xl max-sm:w-[20rem]"></div>
                <motion.div
                    initial={{ width: "8rem" }}
                    whileInView={{ width: "16rem" }}
                    transition={{
                        duration: 0.3,
                        ease: "easeInOut",
                    }}
                    className="absolute inset-auto z-30 h-36 w-64 -translate-y-[6rem] rounded-full bg-cyan-400 blur-2xl max-sm:w-40 max-sm:-translate-y-[4.5rem]"
                ></motion.div>
                <motion.div
                    initial={{ width: "15rem" }}
                    whileInView={{ width: "30rem" }}
                    transition={{
                        duration: 0.3,
                        ease: "easeInOut",
                    }}
                    className="absolute inset-auto z-50 h-0.5 w-[30rem] -translate-y-[7rem] bg-cyan-400 max-sm:w-[20rem] max-sm:-translate-y-[5.5rem]"
                ></motion.div>

                <div className="absolute inset-auto z-40 h-44 w-full -translate-y-[12.5rem] bg-slate-950 max-sm:-translate-y-[9.5rem]"></div>
            </div>

            <div className="relative z-50 flex -translate-y-80 flex-col items-center px-5 max-sm:-translate-y-60">
                {children}
            </div>
        </div>
    );
};

export function LampDemo() {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div ref={ref}>
            <LampContainer>
                <motion.h1
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: inView ? 1 : 0, y: 0 }}
                    transition={{
                        duration: 0.5,
                        ease: "easeOut",
                    }}
                    className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-medium tracking-tight text-white"
                >
                    What They Say <br /> About My Work
                </motion.h1>
                <div className="mt-8 sm:mt-12 md:mt-16 w-full">
                    <InfiniteMovingCards
                        items={testimonials}
                        direction="left"
                        speed="slow"
                    />
                </div>
            </LampContainer>
        </div>
    );
}