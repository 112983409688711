import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../utils/cn";

export const BiggerAnimatedGradientBackground = ({
                                                     gradientBackgroundStart = "rgb(108, 0, 162)",
                                                     gradientBackgroundEnd = "rgb(0, 17, 82)",
                                                     firstColor = "18, 113, 255",
                                                     secondColor = "221, 74, 255",
                                                     thirdColor = "100, 220, 255",
                                                     fourthColor = "200, 50, 50",
                                                     fifthColor = "180, 180, 50",
                                                     pointerColor = "140, 100, 255",
                                                     size = "80%",
                                                     blendingValue = "hard-light",
                                                     children,
                                                     className,
                                                     interactive = true,
                                                     containerClassName,
                                                 }) => {
    const interactiveRef = useRef(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isSafari, setIsSafari] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const animationRef = useRef(null);
    const directionRef = useRef({ x: 1, y: 1 });
    const speedRef = useRef({ x: 5, y: 3 });

    useEffect(() => {
        setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
    }, []);

    useEffect(() => {
        const styles = [
            { key: "--gradient-background-start", value: gradientBackgroundStart },
            { key: "--gradient-background-end", value: gradientBackgroundEnd },
            { key: "--first-color", value: firstColor },
            { key: "--second-color", value: secondColor },
            { key: "--third-color", value: thirdColor },
            { key: "--fourth-color", value: fourthColor },
            { key: "--fifth-color", value: fifthColor },
            { key: "--pointer-color", value: pointerColor },
            { key: "--size", value: size },
            { key: "--blending-value", value: blendingValue },
        ];

        styles.forEach(({ key, value }) => {
            document.documentElement.style.setProperty(key, value);
        });
    }, [
        gradientBackgroundStart, gradientBackgroundEnd, firstColor, secondColor,
        thirdColor, fourthColor, fifthColor, pointerColor, size, blendingValue
    ]);

    const handleMouseMove = (event) => {
        if (interactiveRef.current && isHovering) {
            const rect = interactiveRef.current.getBoundingClientRect();
            setMousePosition({
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
            });
        }
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
        cancelAnimationFrame(animationRef.current);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
        animatePointer();
    };

    const animatePointer = () => {
        if (!isHovering && interactiveRef.current) {
            const rect = interactiveRef.current.getBoundingClientRect();

            setMousePosition(prevPos => {
                let newX = prevPos.x + speedRef.current.x * directionRef.current.x;
                let newY = prevPos.y + speedRef.current.y * directionRef.current.y;

                if (newX <= 0 || newX >= rect.width) {
                    directionRef.current.x *= -1;
                    newX = Math.max(0, Math.min(newX, rect.width));
                }
                if (newY <= 0 || newY >= rect.height) {
                    directionRef.current.y *= -1;
                    newY = Math.max(0, Math.min(newY, rect.height));
                }

                return { x: newX, y: newY };
            });
        }
        animationRef.current = requestAnimationFrame(animatePointer);
    };

    useEffect(() => {
        animatePointer();
        return () => cancelAnimationFrame(animationRef.current);
    }, [isHovering]);

    return (
        <div
            ref={interactiveRef}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cn(
                "relative overflow-hidden bg-[linear-gradient(40deg,var(--gradient-background-start),var(--gradient-background-end))]",
                containerClassName
            )}
        >
            <svg className="hidden">
                <defs>
                    <filter id="blurMe">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                        <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                            result="goo"
                        />
                        <feBlend in="SourceGraphic" in2="goo" />
                    </filter>
                </defs>
            </svg>
            <div className={cn("relative z-10", className)}>{children}</div>
            <div
                className={cn(
                    "gradients-container absolute inset-0 w-full h-full",
                    isSafari ? "blur-2xl" : "[filter:url(#blurMe)_blur(40px)]"
                )}
            >
                {[
                    { color: "first-color", animation: "first" },
                    { color: "second-color", animation: "second" },
                    { color: "third-color", animation: "third" },
                    { color: "fourth-color", animation: "fourth" },
                    { color: "fifth-color", animation: "fifth" },
                ].map((item, index) => (
                    <div
                        key={index}
                        className={cn(
                            `absolute [background:radial-gradient(circle_at_center,_var(--${item.color})_0,_var(--${item.color})_50%)_no-repeat]`,
                            `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
                            `animate-${item.animation}`,
                            "opacity-100"
                        )}
                    ></div>
                ))}
                {interactive && (
                    <div
                        style={{
                            transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`,
                        }}
                        className={cn(
                            `absolute [background:radial-gradient(circle_at_center,_rgba(var(--pointer-color),_0.8)_0,_rgba(var(--pointer-color),_0)_50%)_no-repeat]`,
                            `[mix-blend-mode:var(--blending-value)] w-full h-full -top-1/2 -left-1/2`,
                            `opacity-70 transition-transform duration-100 ease-out`
                        )}
                    ></div>
                )}
            </div>
        </div>
    );
};